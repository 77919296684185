<template>
  <div>
    <b-modal
      id="apptWizModal"
      body-class="appointment-modal"
      :visible="showModal"
      size="lg"
      no-close-on-backdrop
      hide-header
      hide-footer
      lazy
      @hidden="$emit('close')"
    >
      <template #default="{ close }">
        <b-tabs class="w-100" content-class="mt-3" @activate-tab="switchTabs">
          <b-tab title="SCHEDULE" active>
            <div class="d-flex flex-column">
              <b-form-group
                v-if="appointment.serviceDateRequested"
                label="Service Date Requested:"
                label-for="serviceDateRequested"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <div id="serviceDateRequested" class="col-form-label">
                  {{ appointment.serviceDateRequested | date }}
                </div>
              </b-form-group>
              <b-form-group
                v-if="requestedBy"
                label="Service Requested By:"
                label-for="serviceRequestedBy"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <div id="serviceRequestedBy" class="col-form-label">
                  <employee-display :employee="requestedBy" />
                </div>
              </b-form-group>
              <b-form-group
                v-if="appointment.customerComments"
                label="Customer Comments:"
                label-for="customerComments"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <b-input-group id="customerComments">{{ appointment.customerComments }}</b-input-group>
              </b-form-group>
              <b-form-group
                label="Subject/Complaint:"
                label-for="complaint"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <div id="complaint" class="col-form-label">
                  {{ appointment.complaint || NA }}
                </div>
              </b-form-group>

              <b-form-group
                v-if="appointment.startDate"
                label="Start Date/Time:"
                label-for="startDate"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <div id="startDate" class="col-form-label">
                  {{ appointment.startDate | dateTime }}
                </div>
              </b-form-group>

              <b-form-group
                v-if="appointment.endDate"
                label="End Date/Time:"
                label-for="endDate"
                v-bind="{ labelColsSm, labelColsLg, labelColsXl }"
              >
                <div id="endDate" class="col-form-label">
                  {{ appointment.endDate | dateTime }}
                </div>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              UNITS
              <b-badge class="ml-1" variant="success">{{ unitsForAppointment }}</b-badge>
            </template>

            <b-table striped :items="appointment.units" :fields="fields">
              <template #head(fleetUnitId)>
                Fleet
                <span class="nowrap">Unit ID</span>
              </template>
              <template #cell(vin)="{ item }">
                <div>
                  <div>{{ item.vin }}</div>
                  <div class="table-note">{{ item.modelYear }} {{ item.make }} {{ item.model }}</div>
                </div>
              </template>
              <template #cell(branchId)>{{ branch.description }}</template>
            </b-table>
          </b-tab>
          <template #tabs-end>
            <button v-show="showCloseBtn" type="button" class="close ml-auto" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </template>
        </b-tabs>
      </template>
    </b-modal>
  </div>
</template>
<script>
// components
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
// vuex
import { mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';

export default {
  name: 'AppointmentModal',
  components: {
    'employee-display': EmployeeDisplayComponent
  },
  props: {
    appointment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      NA: 'N/A',
      showCloseBtn: true,
      fields: [
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true, thStyle: 'max-width: 80px' },
        { key: 'vin', label: 'VIN/Serial', sortable: true },
        { key: 'branchId', label: 'Branch', sortable: true }
      ],
      labelColsSm: 12,
      labelColsLg: 6,
      labelColsXl: 3
    };
  },
  computed: {
    ...mapGetters({ myBranchMap: LookupGetters.GET_MY_BRANCHES_MAP }),
    showModal() {
      return this.appointment != null;
    },
    branch() {
      return this.myBranchMap[this.appointment.branchId];
    },
    unitsForAppointment() {
      return this.appointment.units?.length;
    },
    requestedBy() {
      if (!this.appointment.serviceRequestId) return null;
      return {
        name: this.appointment.contactName,
        email: this.appointment.contactEmail
      };
    }
  },
  methods: {
    switchTabs(newTabIndex) {
      if (newTabIndex == 1) this.showCloseBtn = false;
      else this.showCloseBtn = true;
    }
  }
};
</script>
